<template>
    <div>
        <div class="page" v-show="$route.path == '/home/caseList'">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>客户案例</el-breadcrumb-item>
                    <el-breadcrumb-item>列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page_operate">
                <vy-search-box
                    v-model="searchObj.inquireValue"
                    :select-list="
                        selectData.map(e => {
                            return { label: e.category_title, value: e.category_title };
                        })
                    "
                    :select-value="searchObj.selectValue"
                    @change="val => (searchObj.selectValue = val)"
                    :btn-click="onInquireInfo"
                    placeholder="请输入名称/关键字"
                    select
                ></vy-search-box>
                <div class="page_handleBtn">
                    <button class="button" @click="chooseAddModuleClick">添加案例</button>
                </div>
            </div>

            <div class="page_content">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column align="center" label="缩略图">
                        <template slot-scope="scope">
                            <img class="content_img" :src="scope.row.little_image" alt="加载失败" :title="scope.row.title" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" align="center" label="案例名称"> </el-table-column>
                    <el-table-column prop="type_of" align="center" label="类型"> </el-table-column>
                    <el-table-column prop="category_title" align="center" label="分类">
                        <template slot-scope="{ row }" v-if="row">
                            <span>{{ row.category_title[0].first_title + " > " + row.category_title[0].second_title }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="update_time" align="center" label="时间"> </el-table-column>
                    <el-table-column align="center" label="置顶">
                        <template slot-scope="{ row }">
                            <div class="content_status" :class="row.is_top ? 'success_color' : 'danger_color'">
                                {{ row.is_top ? "是" : "否" }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="状态">
                        <template slot-scope="scope">
                            <div class="content_status" :class="scope.row.status ? 'success_color' : 'danger_color'">
                                {{ scope.row.status ? "显示" : "隐藏" }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="220">
                        <template slot-scope="scope">
                            <div class="content_operate">
                                <button class="operate_button1" @click="goSetPage(scope.row.id)">编辑</button>
                                <button class="operate_button2" @click="deleteInfoById(scope.row.id)">删除</button>
                            </div>
                            <div class="content_operate">
                                <button class="operate_button3" @click="preview(scope.row)">预览</button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="tableBottom">
                    <vy-pager
                        :count="count"
                        :page-size="pageSize"
                        :page="currentPage"
                        @current-change="currentChange"
                        @size-change="sizeChange"
                    ></vy-pager>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
import axios from "axios";
import qs from "qs";
import VyPager from "@/components/customs/VyPager.vue";
import VySearchBox from "@/components/customs/VySearchBox.vue";

export default {
    components: { VyPager, VySearchBox },
    name: "list_index",
    data() {
        return {
            searchObj: {
                selectValue: "",
                inquireValue: "",
            },
            selectData: [],
            tableData: [],
            count: 0,
            currentPage: 1,
            isSearchEmpty: true,
            cs: "sss",
            pageSize: 20,
        };
    },
    mounted() {
        this.$bus.$on("setBack", () => {
            this.getPageInfo();
        });
        this.getPageInfo();
        this.getSelectData();
    },
    beforeDestroy() {
        console.log("beforeDestroy");
    },
    computed: {},
    methods: {
        // 获取全部数据
        getPageInfo() {
            let _this = this;
            axios
                .post(
                    "/api/Project/byCateGet",
                    qs.stringify({
                        currentPage: this.currentPage,
                        currentPageTotal: this.pageSize,
                        category_title: this.searchObj.selectValue,
                        search: this.searchObj.inquireValue,
                    })
                )
                .then(res => {
                    if (res.data.code !== 200) {
                        this.tableData = [];
                        this.count = 0;
                        return _this.$message.error(res.data.msg);
                    }
                    this.tableData = res.data.data;
                    this.count = res.data.count;
                });
        },
        // 获取一级分类
        async getSelectData() {
            let { data } = await axios.get("/api/project/one");
            this.selectData = data;
        },
        // 分页 -- 当前页改变传值
        currentChange(val) {
            this.currentPage = val;
            this.getPageInfo();
        },
        sizeChange(val) {
            this.pageSize = val;
            this.getPageInfo();
        },

        // 搜索
        onInquireInfo() {
            this.currentPage = 1;
            this.getPageInfo();
        },

        // 删除
        deleteInfoById(id) {
            this.$confirm("是否删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                axios
                    .post(
                        "/api/Project/del_list",
                        qs.stringify({
                            id,
                        })
                    )
                    .then(() => {
                        this.$message({
                            type: "success",
                            message: "删除成功",
                        });
                        this.getPageInfo();
                    });
            });
        },

        // 进入添加案例
        chooseAddModuleClick() {
            // this.$store.commit("chooseModule/onChoose", "add");
            this.$router.push("/home/caseList/add");
        },
        // 进入编辑页
        goSetPage(id) {
            // this.$store.commit("chooseModule/onChoose", "set");
            this.$router.push("/home/caseList/set/" + id);
        },

        // 预览
        preview({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return this.$message.error("富文本与浏览链接均为空！！");
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            window.open("https://vu818.com/#/case/listDetail/" + id, "_blank");
        },
    },
};
</script>
<style scoped lang="less">
.page {
    .page_operate {
        margin: 20px 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .page_content {
        margin-top: 10px;
        .el-table {
            border-radius: 20px;
        }
        .content_img {
            width: 100%;
            // height: 100px;
            object-fit: cover;
        }
        .content_status,
        .content_isTop {
            width: 54px;
            height: 33px;
            line-height: 33px;
            color: #fff;
            margin: 0 auto;
            border-radius: 20px;
        }
        .content_operate {
            display: flex;
            justify-content: space-around;
            align-items: center;

            button {
                width: 92px;
                height: 36px;
                border-radius: 16px;
                border: 0;
                font-size: 14px;
                letter-spacing: 2px;
                color: #fff;
                margin: 5px 0;
                cursor: pointer;
            }

            .operate_button1 {
                background: linear-gradient(to right, #da8cff, #9a55ff);
            }

            .operate_button2 {
                background: linear-gradient(to right, #f38ca8, #fe7096);
            }

            .operate_button3 {
                background: linear-gradient(to right, #84d9d2, #07cdae);
            }
            .operate_button4 {
                // background: linear-gradient(to left, #16222a, #3a6073);
                background: linear-gradient(to right, #ffbf96, #fe7096);
            }
        }
    }
}
.tableBottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666;
    font-size: 14px;
}
</style>
